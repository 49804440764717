<template>
  <div class="container-fluid">
    <PageTitle />

    <template v-if="isList">
      <card-list
        :filter.sync="filter"
        :dataListing.sync="data"
        :idKey="idKey"
        :fields="fields"

        :no-btn-mass="true"
        :no-multi-select="true"
      >
        <template #muk_is_active="data">
          <LabelStatus :status="data.scope.item[statusKey]" />
        </template>
        <template #muk_level="data">
          {{
            data.scope.item.muk_level.charAt(0).toUpperCase() +
              data.scope.item.muk_level.slice(1)
          }}
        </template>
        <template #aksi="data">
          <div 
            v-if="!moduleRole('ViewOnly')"
            class="btn-toolbar"
          >
            <b-dropdown no-flip>
              <template #button-content>
                <i class="icon-menu"></i>
              </template>
              <b-dropdown-item
                :to="{
                  name: $route.name,
                  params: { pageSlug: data.scope.item[idKey] },
                }"
              >
                Ubah Data
              </b-dropdown-item>
              <b-dropdown-item
                @click="doDelete(data.scope.index, data.scope.item)"
                >Hapus</b-dropdown-item
              >
            </b-dropdown>
          </div>
          <span v-else>-</span>
        </template>
      </card-list>
    </template>

    <div v-else class="card">
      <div class="card-body">
        <h5 class="card-title mb-3">
          {{ isAdd ? "Tambah" : "Ubah" }} {{ pageTitle }}
        </h5>

        <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
          <form @submit.prevent="handleSubmit(doSubmitCRUD)">
            <b-row class="mb-2">
              <b-col md="3">
                <b-form-group>
                  <template #label>
                    Tahun <span class="text-danger mr5">*</span>
                  </template>
                  <b-input type="text" v-model="row.mkr_tahun" />
                  <VValidate
                    name="Tahun Risiko Korporat"
                    v-model="row.mkr_tahun"
                    :rules="mrValidation.mkr_tahun"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="10">
                <b-form-group>
                  <template #label>
                    Nama Risiko <span class="text-danger mr5">*</span>
                  </template>
                  <b-input type="text" v-model="row.mkr_name" />
                  <VValidate
                    name="Nama Risiko Korporat"
                    v-model="row.mkr_name"
                    :rules="mrValidation.mkr_name"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <div class="row mt-4">
              <div class="col-md-2 offset-md-10">
                <button
                  type="submit"
                  class="btn btn-block btn-rounded  btn-info"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<script>
import GlobalVue from "@libs/Global";
import PageTitle from "@view/include/PageTitle";
import CardList from "@/components/CardList";

export default {
  extends: GlobalVue,
  components: {
    PageTitle,
    CardList,
  },
  data() {
    return {
      idKey: "mkr_id",
      statusKey: "mkr_is_active",
      fields: [
        { key: "select", label: "" },
        { key: "number", label: "#" },
        { key: "mkr_tahun", label: "Tahun" },
        { key: "mkr_name", label: "Nama Risiko" },
        { key: "aksi", is_custom: true },
      ],
    };
  },
  mounted() {
    this.apiGet();
  },
  watch: {
    $route() {
      this.apiGet();
    },
  },
};
</script>
